import React, { Profiler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import List from "./List";

const Navbar = () => {
  const navigate = useNavigate();



  return (
    <>
     
     <div className="tbgc">
       

    <h1 className="head"><center>Go early and cast your vote.</center></h1>




       
        <button className="button-56"
          onClick={() => {
            navigate("/Login");
          }}
        >
        Login
        </button>
        
        <button className="button-56"
          onClick={() => {
            navigate("/Register");
          }}
        >
        Register
        </button>     
        

 

 {/* <center><h1>XYZ PVT LTD</h1></center> */}

{/* <img className="imgE" src="https://wallpaperaccess.com/full/656670.jpg" width="1520" height="700" alt=""/> */}

</div>


        {/* <button className="btn" onClick={updateAllData}>Update Records</button> */}

        {/* <img className="imgE" src="https://tse4.mm.bing.net/th?id=OIP.6IoR9dyxrCKArBErYA6LRAHaEv&pid=Api&P=0&h=180" width="1700" height="800" alt=""/> */}
      

    </>
  );
};

export default Navbar;
