import React from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import VaddData from './components/VaddData'
import Vupdate from './components/Vupdate'
import Navbar from './components/Navbar'
import VReports from './components/VReports'


import Voters from './components/Voters'
import CReports from './components/CReports'
import CAddData from './components/CAddData'
import CUpdate from './components/CUpdate'
import Login from './components/Login'

import Vote from './components/Vote'
import Register from './components/Register'


const App = () => {
  return (
    <>
        <BrowserRouter>
<Routes>
<Route path= "/Login" element={ <Login/>} />

<Route path= "/" element={ <Navbar />} />

    <Route path= "/Register" element={ <Register />} />
    <Route path= "/VaddData" element={ <VaddData />} />
    <Route path= "/Vupdate/:id" element={ <Vupdate />} />
    <Route path= "/VReports" element={ <VReports />} />

   

    <Route path= "/Voters" element={ <Voters/>} />
    <Route path= "/CReports" element={ <CReports/>} />
    <Route path= "/caddData" element={ <CAddData/>} />
   
    <Route path= "/CUpdate/:id" element={ <CUpdate/>} />
   

     <Route path= "/Vote" element={ <Vote/>} />
</Routes>
    </BrowserRouter>
    </>
  )
}

export default App