import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Vlist = (props) => {
  const navigate = useNavigate()
    const {id,username,voteridno,gender,dateofbirth,fathername,imgurl} = props;
     

    //  const mtotalsalary = 0
    //  const ytotalsalary = 0
    //  const total = e_yearly_salary+e_monthly_salary;
   



    const handledelete = () => {
        const id = props.id;
        try {
          axios
            .post("http://localhost:3001/delete", { id })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      };
    
      return (
        // <div className='employeeData2'>
        // <div className='employeeData21'>
        //     <div> id : {id}</div>
        //     <div>name : {e_name}</div>
        //     <div>Msalary : {e_monthly_salary} </div>
        //     <div>Ysalary : {e_yearly_salary} </div>
        //     {/* <div> {total} </div> */}
    
        //     <button onClick={handledelete2}>delete2</button>
        //     <button onClick={() => {navigate(`/Update2/${id}`)}}>Update2</button>  </div>
        // </div>
    
        <div className="card">
          <div className="top">
            <h3 className="name">username :{username}</h3>
            <img className="circle-img" src={props.imgurl} alt="avatar_img" />
          </div>
          <div className="bottom">
            {/* <p className="info">{props.name}</p> */}
            <p className="info">voteridno : {voteridno}</p>
            <p className="info">gender : {gender}</p>
            <p className="info">dateofbirth : {dateofbirth}</p>
            <p className="info">fathername : {fathername}</p>
        
            <button onClick={handledelete}>delete</button>
            <button
              onClick={() => {
                navigate(`/Vupdate/${id}`);
              }}
            >
              Update
            </button>
          </div>
        </div>
      );
    };

export default Vlist