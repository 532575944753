import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Vlist from "../components/Vlist";

const VReports  = () => {
  const navigate = useNavigate();
  const [employeeData, setData] = useState([]);
//   var yearnings  = 0;
//   var mearnings = 0;



  useEffect(() => {
    axios
      .get("http://localhost:3001/employeeData")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [employeeData]);

  return (
    <>
      <center>
        <h3 className="he">
          Election
        </h3>
        <button className="button-73"
          onClick={() => {
            navigate("/VaddData");
          }}
        >
          Add Data
        </button>
      </center>
      <div className="employeeList">
        {/* <div className="employeeData">
          <div>id</div>
          <div> username </div>
          <div> voteridno </div>
          <div> gender </div>
          <div> dateofbirth </div>
          <div>fathername</div>
          <div>imgurl</div>
          
        </div> */}
        {employeeData.map((vote) => {

           {/* mearnings =mearnings + vote.mearnings;
          yearnings = yearnings + vote.mearnings * 12; */}
          {/* yearnings = mearnings * 12; */}
       

          return (
            <Vlist
              key={vote.id}
              id={vote.id}
              username={vote.username}
              voteridno={vote.voteridno}
              gender={vote.gender}
              dateofbirth={vote.dateofbirth}
              fathername = {vote.fathername}
             imgurl = {vote.imgurl}
            />
          );
        })}
        <div>
      
        </div>
      </div>
      <button className="button-73"
        onClick={() => {
            navigate("/Voters");
        }}
        >GO BACK</button>
    </>
  );
};

export default VReports ;