import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const VaddData = () => {
    const navigate = useNavigate()
    const [username,setU_name] = useState("")
    const [voteridno,setV_idno] = useState("")
    const [gender,setGender] = useState("")
    const [dateofbirth,setDob] = useState("")
    const [fathername,setFname] = useState("")
    const [imgurl,setimg] = useState("")
   

   

const handleSubmit = (e) =>{
e.preventDefault()

axios
.post("http://localhost:3001/addEmployee", {username,voteridno,gender,dateofbirth,fathername,imgurl})
.then(
  (response) => {
   console.log(response.data);
   navigate('/VReports')
    }
  )
.catch(
  (error) => {
    console.log(error);
  }
  )

}


  return (
    <div>
        <form onSubmit={handleSubmit}>
            
            <input type='text' placeholder='username' name='username' value={username} onChange={(e)=>{setU_name(e.target.value)}} required />
            <input type='number' placeholder='voteridno' name='voteridno' value={voteridno} onChange={(e)=>{setV_idno(e.target.value)}} required />
            <input type='text' placeholder='gender' name='gender' value={gender} onChange={(e)=>{setGender(e.target.value)}} required />
            <input type='number' placeholder='dob' name='dob' value={dateofbirth} onChange={(e)=>{setDob(e.target.value)}} required />
            <input type='text' placeholder='fathername' name='fathername' value={fathername} onChange={(e)=>{setFname(e.target.value)}} required />
            <input type='text' placeholder='imgurl' name='imgurl' value={imgurl} onChange={(e)=>{setimg(e.target.value)}} required />
            {/* <input type='number' placeholder='v_total' name='v_total' value={v_total} onChange={(e)=>{setv_total(e.target.value)}} required /> */}
            <input type='submit' value="submit" />
        </form>
    </div>
  )
}

export default VaddData;