import React from 'react'
import { useNavigate } from 'react-router-dom'

const Voters = () => {
    const navigate = useNavigate()
  return (
    <>
<button className="button-50"
      onClick={() => {
                   navigate("/VReports");
           }}
         >  
           VotersReport
       </button>

       <button className="button-50"
      onClick={() => {
                   navigate("/CReports");
           }}
         >
          CandidatesReport
       </button>

       <button className="button-50"
          onClick={() => {
            navigate("/Vote");
          }}
        >
          vote
        </button>

       <button className="button-50"
      onClick={() => {
                   navigate("/");
           }}
         >
          goback
       </button>



    </>
  )
}

export default Voters



